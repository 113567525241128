import { select } from '@redux-saga/core/effects'
import { selectUserToken } from './auth.selectors'
import moment from 'moment'
import { type JWT } from 'aws-amplify/auth'

export function * isTokenAboutToExpire () {
  const userToken: JWT = yield select(selectUserToken)
  const userTokenExp = userToken?.payload?.exp

  if (!userTokenExp) {
    return true
  }

  const userTokenExpMoment = moment.unix(userTokenExp)
  const now = moment().subtract(1, 'hour')

  return !userTokenExp || userTokenExpMoment.isSameOrBefore(now)
}
