import { type JWT } from 'aws-amplify/auth'
import { type User } from '../../interfaces/user.interface'
import { type AppState } from '../index/index.reducer'

export const selectUserToken = (state: AppState): JWT | undefined => state.auth.userToken

export const selectCurrentUser = (state: AppState): User | undefined => state.auth.currentUser

export const selectIsAuthLoading = (state: AppState): boolean => state.auth.isLoading

export const selectAuthErrorMessage = (state: AppState): string | undefined => state.auth.errorMessage
