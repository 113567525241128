import styled from 'styled-components'

export const DeleteAccountPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.background.primary};
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
`

export const DeleteAccountPageContainer = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.background.level0};
  max-width: 80%;
`

export const DeleteAccountPageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`

export const DeleteAccountPageDeleteButtonContainer = styled.div`
  margin-top: 64px;
  align-self: center;
`
