import axios from 'axios'
import { call, select } from 'redux-saga/effects'
import { refreshTokenSaga } from '../redux/auth/auth.sagas'
import { selectUserToken } from '../redux/auth/auth.selectors'
import { type JWT } from 'aws-amplify/auth'
import { type List } from 'src/interfaces/list.interface'

const GSBEServiceUrl = process.env.REACT_APP_GSBE_SERVICE_BASE_URL

const buildHeaders = (token?: JWT) => {
  if (token) {
    return {
      headers: {
        Authorization: `${token.toString()}`
      }
    }
  }
}

function * getRequest<R> (url: string) {
  yield call(refreshTokenSaga)

  const token: JWT | undefined = yield select(selectUserToken)
  const response: R = yield axios.get<R>(url, buildHeaders(token))
  return response
}

// function * postRequest<P, R> (url: string, data: P) {
//   yield call(refreshTokenSaga)

//   const token: JWT | undefined = yield select(selectUserToken)
//   const response: R = yield axios.post(url, data, buildHeaders(token))
//   return response
// }

// function * putRequest<P, R> (url: string, data: P) {
//   yield call(refreshTokenSaga)

//   const token: JWT | undefined = yield select(selectUserToken)
//   const response: R = yield axios.put(url, data, buildHeaders(token))
//   return response
// }

function * deleteRequest<R> (url: string) {
  yield call(refreshTokenSaga)

  const token: JWT | undefined = yield select(selectUserToken)
  const response: R = yield axios.delete<R>(url, buildHeaders(token))
  return response
}

// Auth
function * deleteAccount () {
  yield deleteRequest(`${GSBEServiceUrl}/auth/auth/deleteAccount`)
}

// Lists
function * getList (listId: string) {
  return getRequest<any>(`${GSBEServiceUrl}/list/list/${listId}`)
}

function * getLists (userId: string): Generator<any> {
  return yield getRequest<List[]>(`${GSBEServiceUrl}/list/lists/${userId}`)
}

export default {
  deleteAccount,
  getList,
  getLists
}
