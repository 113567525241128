import React, { type PropsWithChildren } from 'react'
import { StyledSubheader } from './Subheader.styles'

const Subheader = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <StyledSubheader>
      {children}
    </StyledSubheader>
  )
}

export default Subheader
