import React, { type PropsWithChildren } from 'react'
import { PrimaryButton } from './Button.styles'

interface ButtonProps {
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
}

const Button = ({
  children,
  onClick,
  type = 'button'
}: PropsWithChildren<ButtonProps>): JSX.Element => {
  return (
    <PrimaryButton onClick={onClick} type={type}>
      {children}
    </PrimaryButton>
  )
}

export default Button
