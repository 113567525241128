import styled from 'styled-components'

export const PrimaryButton = styled.button`
  border-radius: 12px;
  background-color: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text.defaultLight};
  border: none;
  padding: 12px;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`
