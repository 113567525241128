import { type DefaultTheme } from 'styled-components'

const LightTheme: DefaultTheme = {
  colors: {
    background: {
      primary: 'rgba(87, 188, 144, 1)',
      lightPrimary: 'rgba(139, 206, 175, 1)',
      // #15 marine https://www.canva.com/learn/website-color-schemes/
      success: 'rgba(40, 167, 69, 1)',
      danger: 'rgba(188, 87, 131, 1)', // Old Value: 'rgba(166, 52, 70, 1)'
      warning: 'rgba(229, 143, 101, 1)', // Old Value:  'rgba(255, 193, 7, 1)'
      level0: 'rgba(255, 255, 255, 1)',
      level1: 'rgba(240, 240, 240, 1)',
      level2: 'rgba(225, 225, 225, 1)',
      level3: 'rgba(210, 210, 210, 1)',
      level4: 'rgba(195, 195, 195, 1)',
      overlay: 'rgba(0.5, 0.25, 0, 0.4)'
    },

    text: {
      warning: 'rgba(52, 58, 64, 1)',
      defaultDark: 'rgba(66, 66, 66, 1)',
      defaultDark2: 'rgba(66, 66, 66, 0.65)',
      defaultLight: 'rgba(255, 255, 255, 1)'
    }
  }
}

export default LightTheme
