import './App.css'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './navigation/AppNavigator'
import { ThemeProvider } from 'styled-components'
import LightTheme from './themes/LightTheme'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import indexReducer from './redux/index/index.reducer'
import rootSaga from './redux/index/index.sagas'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import config from './amplifyconfiguration.json'
import { Amplify } from 'aws-amplify'
import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: false,
  environment: process.env.REACT_APP_ENVIRONMENT
})

Amplify.configure(config)

function App (): JSX.Element {
  const sagaMiddleware = createSagaMiddleware()
  // mount it on the Store
  const store = configureStore({
    reducer: indexReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false
      // {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // }
    }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
  })

  // then run the saga
  sagaMiddleware.run(rootSaga)

  return (
    <Provider store={store}>
      <ThemeProvider theme={LightTheme}>
        <div className='appWrapper'>
          <BrowserRouter>
            <div className='appContent'>
              <AppNavigator />
              <ToastContainer />
            </div>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default App
