import Header from 'src/components/Text/Header/Header'
import { DeleteAccountPageContainer, DeleteAccountPageContent, DeleteAccountPageDeleteButtonContainer, DeleteAccountPageWrapper } from './DeleteAccountPage.styles'
import Subheader from 'src/components/Text/Subheader/Subheader'
import Button from 'src/components/Button/Button'
import React, { useEffect, type Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type UnknownAction } from 'redux'
import { deleteAccount } from 'src/redux/auth/auth.constants'
import { signOut } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from 'src/redux/auth/auth.selectors'

const onDeleteAccount = (dispatch: Dispatch<UnknownAction>) => {
  dispatch(deleteAccount())
  void signOut()
}

const DeleteAccountPage = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [currentUser])

  return (
    <DeleteAccountPageWrapper>
      <DeleteAccountPageContainer>
        <DeleteAccountPageContent>
          <Header>
            Delete Loaf Account?
          </Header>
          <Subheader>
            Are you sure you want to delete your account? This will delete you account across all of your devices. This action cannot be undone.
          </Subheader>

          <DeleteAccountPageDeleteButtonContainer>
            <Button onClick={() => { onDeleteAccount(dispatch) } }>Delete account</Button>
          </DeleteAccountPageDeleteButtonContainer>
        </DeleteAccountPageContent>
      </DeleteAccountPageContainer>
    </DeleteAccountPageWrapper>
  )
}

export default DeleteAccountPage
