import React, { type PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { selectUserToken } from 'src/redux/auth/auth.selectors'

export const RequireAuth = ({ children }: PropsWithChildren): JSX.Element => {
  const userToken = useSelector(selectUserToken)
  const location = useLocation()

  if (!userToken) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return <>{children}</>
}
