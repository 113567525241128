import { type UnknownAction, combineReducers } from 'redux'
import auth, { type AuthState } from '../auth/auth.reducer'
import { type Action, indexActions, successful } from './index.constants'

export interface AppState {
  auth: AuthState
}

const appReducers = combineReducers({
  auth
})

const indexReducer = (state: AppState | undefined, action: Action) => {
  switch (action.type) {
    case successful(indexActions.CLEAR_APP_STATE):
      return appReducers(undefined, action as UnknownAction)

    default:
      return appReducers(state, action as UnknownAction)
  }
}

export default indexReducer
