export const successful = (actionType: string) => `${actionType}_SUCCESSFUL`

export const failure = (actionType: string) => `${actionType}_FAILURE`

export const indexActions = {
  CLEAR_APP_STATE: 'CLEAR_APP_STATE'
}

export const clearAppState = () => ({ type: indexActions.CLEAR_APP_STATE })

export interface Action {
  type: string
  payload?: any
}

export interface ReduxAction<T> {
  type: string
  payload: T
}

export interface ReduxActionWithoutPayload {
  type: string
}
