import { takeLatest, takeLeading } from 'redux-saga/effects'
import { authActions } from '../auth/auth.constants'
import {
  deleteAccountSaga,
  getCurrentUserSaga,
  refreshTokenSaga,
  signInSaga
} from '../auth/auth.sagas'

// eslint-disable-next-line max-statements
export default function * rootSaga () {
  try {
    yield takeLatest(authActions.DELETE_ACCOUNT, deleteAccountSaga)
    yield takeLatest(authActions.REFRESH_TOKEN, refreshTokenSaga)
    yield takeLeading(authActions.SIGN_IN, signInSaga)
    yield takeLeading(authActions.GET_CURRENT_USER, getCurrentUserSaga)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error running sagas', e)
  }
}
