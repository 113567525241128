import React, { useEffect, type Dispatch } from 'react'
import splashImage from '../../assets/images/loaf-splash.png'
import Button from 'src/components/Button/Button'
import TextInput from 'src/components/TextInput/TextInput'
import {
  LoginForm,
  LoginFormContainer,
  LoginFormHeaderContainer,
  LoginFormInputContainer,
  LoginFormWrapper,
  LoginPageContainer,
  LoginPageFormSection,
  LoginPageSection,
  LoginPageWrapper
} from './LoginPage.styles'
import useMediaQuery from 'src/utils/hooks/useMediaQuery/useMediaQuery'
import Header from 'src/components/Text/Header/Header'
import Subheader from 'src/components/Text/Subheader/Subheader'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { type UnknownAction } from 'redux'
import { signIn } from 'src/redux/auth/auth.constants'
import { selectCurrentUser } from 'src/redux/auth/auth.selectors'

const onLogIn = (values: LoginFormValues, dispatch: Dispatch<UnknownAction>): void => {
  dispatch(signIn(values))
}

interface LoginFormValues {
  email: string
  password: string
}

const LoginPage = (): JSX.Element => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: (values) => { onLogIn(values, dispatch) }
  })

  useEffect(() => {
    if (currentUser) {
      navigate('/account/delete-account')
    }
  }, [currentUser])

  return (
    <LoginPageWrapper>
      <LoginPageContainer>
        <LoginPageFormSection>
          <LoginFormWrapper>
            <LoginFormContainer>
              <LoginFormHeaderContainer>
                <Header>
                  Hey There!
                </Header>
                <Subheader>
                  Welcome to Loaf.
                </Subheader>
              </LoginFormHeaderContainer>
              <LoginForm onSubmit={formik.handleSubmit}>
                <LoginFormInputContainer>
                  <TextInput name='email' onChange={formik.handleChange} placeholder='Email' inputType='email' />
                  <TextInput name='password' onChange={formik.handleChange} placeholder='Password' inputType='password' />
                </LoginFormInputContainer>
                <Button
                  type='submit'
                >
                  Log in
                </Button>
              </LoginForm>
            </LoginFormContainer>
          </LoginFormWrapper>
        </LoginPageFormSection>
        {isDesktop && (
          <LoginPageSection>
            <div style={{ display: 'flex' }}>
              <img src={splashImage} alt='' height='100%' width='100%' />
            </div>
          </LoginPageSection>
        )}
      </LoginPageContainer>
    </LoginPageWrapper>
  )
}

export default LoginPage
