import styled from 'styled-components'

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  color: ${props => props.theme.colors.text.defaultDark};
`

export const StyledTextInput = styled.input`
  background-color: ${props => props.theme.colors.background.level1};
  border-radius: 12px;
  padding: 16px 12px;
  border: none;

  &:focus-visible {
    outline-color: ${props => props.theme.colors.background.level3};
  }
`
