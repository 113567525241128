import React, { type PropsWithChildren } from 'react'
import { StyledHeader } from './Header.styles'

const Header = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <StyledHeader>
      {children}
    </StyledHeader>
  )
}

export default Header
