import { type JWT } from 'aws-amplify/auth'
import { type User } from '../../interfaces/user.interface'
import {
  authActionFailure,
  deleteAccountSuccess,
  getCurrentUserSuccess,
  refreshTokenSuccess,
  signIn,
  signInSuccess
} from './auth.constants'
import { createReducer, isAnyOf } from '@reduxjs/toolkit'

export interface AuthState {
  userToken?: JWT
  currentUser?: User
  isLoading: boolean
  errorMessage?: string
}

const initialState: AuthState = {
  userToken: undefined,
  currentUser: undefined,
  isLoading: false,
  errorMessage: undefined
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(authActionFailure, (state) => {
      state.isLoading = false
    })
    .addCase(refreshTokenSuccess, (state, action) => {
      state.userToken = action.payload.userToken as any
    })
    .addCase(deleteAccountSuccess, (state) => {
      state.userToken = undefined
      state.currentUser = undefined
      state.isLoading = false
      state.errorMessage = undefined
    })
    .addMatcher(isAnyOf(signInSuccess, getCurrentUserSuccess), (state, action) => {
      state.userToken = action.payload.userToken as any
      state.currentUser = action.payload.currentUser
      state.isLoading = false
      state.errorMessage = undefined
    })
    .addMatcher(isAnyOf(signIn), (state) => {
      state.isLoading = true
      state.errorMessage = undefined
    })
    .addDefaultCase(() => {})
})
