import React, { type ChangeEvent } from 'react'
import { StyledTextInput, TextInputContainer } from './TextInput.styles'

interface TextInputProps {
  inputType?: string
  label?: string
  placeholder?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
}

const TextInput = ({
  inputType = 'text',
  label,
  placeholder,
  onChange,
  name
}: TextInputProps): JSX.Element => {
  const hasLabel = label !== undefined && label !== null
  return (
    <TextInputContainer>
      {hasLabel && <label>{label}</label>}
      <StyledTextInput
        type={inputType}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
      />
    </TextInputContainer>
  )
}

export default TextInput
