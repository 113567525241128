import { createAction } from '@reduxjs/toolkit'
import { failure, successful } from '../index/index.constants'
import {
  type GetCurrentUserSuccessPayload,
  type RefreshTokenSuccessPayload,
  type SignInPayload,
  type SignInSuccessPayload
} from './auth.interface'

export const amplifyErrorCodes = {
  USER_NOT_FOUND: 'UserNotFoundException',
  USER_ALREADY_EXISTS: 'UsernameExistsException',
  INVALID_CONFIRMATION_CODE: 'CodeMismatchException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  EXPIRED_CODE: 'ExpiredCodeException',
  CODE_DELIVERY_FAILURE: 'CodeDeliveryFailureException',
  INVALID_PARAMETER: 'InvalidParameterException',
  USER_NOT_CONFIRMED: 'UserNotConfirmedException'
}

export const signInErrorMessages = {
  [amplifyErrorCodes.USER_NOT_FOUND]: 'Invalid username or password.',
  [amplifyErrorCodes.NOT_AUTHORIZED]: 'Invalid username or password.'
}

export const signUpErrorMessages = {
  [amplifyErrorCodes.USER_ALREADY_EXISTS]:
    'A user with the provided email already exists.',
  // eslint-disable-next-line max-len
  [amplifyErrorCodes.CODE_DELIVERY_FAILURE]:
    'Failed to send a confirmation code to the provided email address.  Double check that it is a valid email.'
}

export const confirmationCodeErrorMessages = {
  [amplifyErrorCodes.INVALID_CONFIRMATION_CODE]:
    'Invalid confirmation code provided, please try again.',
  [amplifyErrorCodes.NOT_AUTHORIZED]:
    'The user associated with that confirmation code has already been confirmed. Try signing in instead.',
  [amplifyErrorCodes.INVALID_PARAMETER]:
    'The user associated with that confirmation code has already been confirmed. Try signing in instead.',
  [amplifyErrorCodes.EXPIRED_CODE]:
    'This confirmation code has expired. Try resending another code.',
  [amplifyErrorCodes.USER_NOT_FOUND]:
    'The user associated with that confirmation code could not be found. Try signing up instead.'
}

export const getForgotPasswordCodeErrorMessages = {
  [amplifyErrorCodes.USER_NOT_FOUND]:
    "Couldn't find a user with that email. Verify that it is correct.",
  // eslint-disable-next-line max-len
  [amplifyErrorCodes.CODE_DELIVERY_FAILURE]:
    'Failed to send a confirmation code to the provided email address. Double check that it is a valid email.',
  [amplifyErrorCodes.INVALID_PARAMETER]:
    "Can't reset the password for a user without a confirmed email address."
}

export const updateForgotPasswordErrorMessages = {
  [amplifyErrorCodes.INVALID_CONFIRMATION_CODE]:
    'Invalid confirmation code provided, please try again.',
  [amplifyErrorCodes.EXPIRED_CODE]:
    'This confirmation code has expired. Try requesting another code on the previous page.'
}

export const changePasswordErrorMessages = {
  [amplifyErrorCodes.INVALID_PARAMETER]: 'Invalid password.',
  [amplifyErrorCodes.NOT_AUTHORIZED]: 'Invalid password.'
}

export const generalCodeErrorMessages = {
  [amplifyErrorCodes.LIMIT_EXCEEDED]:
    'Attempt limit exceeded, please try again another time.'
}

export const authActions = {
  SIGN_IN: 'SIGN_IN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  AUTH_ACTION_FAILURE: 'AUTH_ACTION_FAILURE',
  GET_CURRENT_USER: 'GET_CURRENT_USER'
}

export const refreshToken = createAction(authActions.REFRESH_TOKEN)

export const refreshTokenSuccess = createAction<RefreshTokenSuccessPayload>(
  successful(authActions.REFRESH_TOKEN)
)

export const getCurrentUser = createAction(authActions.GET_CURRENT_USER)

export const getCurrentUserSuccess = createAction<GetCurrentUserSuccessPayload>(
  successful(authActions.GET_CURRENT_USER)
)

export const signIn = createAction<SignInPayload>(authActions.SIGN_IN)

export const signInSuccess = createAction<SignInSuccessPayload>(
  successful(authActions.SIGN_IN)
)

export const deleteAccount = createAction(authActions.DELETE_ACCOUNT)

export const deleteAccountSuccess = createAction(
  successful(authActions.DELETE_ACCOUNT)
)

export const authActionFailure = createAction(
  failure(authActions.AUTH_ACTION_FAILURE)
)
