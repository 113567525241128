import {
  signIn,
  getCurrentUser,
  type GetCurrentUserOutput,
  fetchAuthSession
} from 'aws-amplify/auth'
import { call, put } from 'redux-saga/effects'
import {
  signInErrorMessages,
  generalCodeErrorMessages,
  authActionFailure,
  refreshTokenSuccess,
  signInSuccess,
  getCurrentUserSuccess,
  deleteAccountSuccess
} from './auth.constants'
import * as Sentry from '@sentry/react'
// import Toast from 'react-native-toast-message'
import { isTokenAboutToExpire } from './auth.util'
// import GSBEService from '../../api/GSBEService'
import { type User } from '../../interfaces/user.interface'
import {
  type ReduxAction,
  type ReduxActionWithoutPayload
} from '../index/index.constants'
import { type SignInPayload } from './auth.interface'
import { type AuthSession } from '@aws-amplify/core/dist/esm/singleton/Auth/types'
import GSBEService from 'src/api/GSBEService'
import { toast } from 'react-toastify'

function * handleError (error: any, errorText: string) {
  toast.error(errorText)

  Sentry.captureException(error)
}

function * handleAuthError (
  error: any,
  amplifyDisplayErrorMessages: Record<string, string>,
  errorTitle: string
) {
  if (error?.name) {
    let errorMessage = amplifyDisplayErrorMessages[error.name]

    if (!errorMessage) {
      errorMessage = generalCodeErrorMessages[error.name]
    }

    yield put(authActionFailure())

    toast.error(errorMessage)
  } else {
    yield put(authActionFailure())

    yield handleError(error, errorTitle)
  }
}

export function * refreshTokenSaga () {
  try {
    const aboutToExpire: boolean = yield call(isTokenAboutToExpire)
    if (aboutToExpire) {
      const session: AuthSession = yield call(fetchAuthSession)
      const userToken = session.tokens?.idToken

      if (userToken) {
        yield put(refreshTokenSuccess({ userToken }))
      }
    }
  } catch (e) {
    yield handleError(e, 'Error Refreshing Token')
    Sentry.captureException(e)
  }
}

export function * getCurrentUserSaga () {
  try {
    const user: GetCurrentUserOutput = yield call(getCurrentUser)
    const session: AuthSession = yield call(fetchAuthSession)
    const userToken = session.tokens?.idToken

    if (!userToken) {
      throw new Error('UserToken could not be retrieved from the user session.')
    }

    const currentUser: User = {
      id: user.userId,
      email: ''
    }

    yield put(getCurrentUserSuccess({ currentUser, userToken }))
  } catch (error: any) {
    if (error?.name !== 'UserUnAuthenticatedException') {
      yield call(handleAuthError, error, signInErrorMessages, 'Error Getting Current User')
    }
  }
}

export function * signInSaga (action: ReduxAction<SignInPayload>) {
  const { email, password } = action.payload

  try {
    if (!email || !password) {
      throw new Error('Email and password are required to sign in')
    }

    yield call(signIn, { username: email, password })
    const user: GetCurrentUserOutput = yield call(getCurrentUser)
    const session: AuthSession = yield call(fetchAuthSession)
    const userToken = session.tokens?.idToken

    if (!userToken) {
      throw new Error('UserToken could not be retrieved from the user session.')
    }

    const currentUser: User = {
      id: user.userId,
      email
    }

    yield put(signInSuccess({ currentUser, userToken }))
  } catch (error: any) {
    yield call(handleAuthError, error, signInErrorMessages, 'Error Signing In')
  }
}

export function * deleteAccountSaga (action: ReduxActionWithoutPayload) {
  try {
    yield call(GSBEService.deleteAccount)
    yield put(deleteAccountSuccess())

    toast.success('Your account has been successfully deleted')
  } catch (error) {
    yield call(handleAuthError, error, {}, 'Error Deleting Account')
  }
}
