import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/login/LoginPage'
import DeleteAccountPage from 'src/pages/account/delete-account/DeleteAccountPage'
import { RequireAuth } from './utils'
import { useDispatch } from 'react-redux'
import { getCurrentUser, refreshToken } from 'src/redux/auth/auth.constants'

const AppNavigator = (): JSX.Element => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(refreshToken())
    dispatch(getCurrentUser())
  }, [])

  return (
    <Routes>
      <Route index element={
        <RequireAuth>
          <DeleteAccountPage />
        </RequireAuth>} />
      <Route path='/login' element={<LoginPage />} />
      <Route
        path='/account/delete-account'
        element={
          <RequireAuth>
            <DeleteAccountPage />
          </RequireAuth>
        }
      />
      <Route path='*' element={
        <RequireAuth>
          <DeleteAccountPage />
        </RequireAuth>} />
    </Routes>
  )
}

export default AppNavigator
