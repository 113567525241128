import styled from 'styled-components'

export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.background.primary};
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
`

export const LoginPageContainer = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.background.level0};
  max-width: 80%;
`

export const LoginPageSection = styled.div`
  width: 50%;
`

export const LoginPageFormSection = styled(LoginPageSection)`
  display: flex;
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const LoginFormContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`

export const LoginFormHeaderContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const LoginFormInputContainer = styled.div`
  margin-bottom: 44px;
`

export const LoginForm = styled.form`
  width: 100%;
`
